import React from 'react'
import {CKEditor} from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

export const Editor = ({field, form, ...props}) => {
  return (
    <CKEditor
      editor={ClassicEditor}
      data={field.value}
      onChange={(event, editor) => {
        const data = editor.getData()
        form.setFieldValue(field.name, data)
      }}
    />
  )
}

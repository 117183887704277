import {FC, useEffect, useMemo} from 'react'
import Flatpickr from 'react-flatpickr'
import 'flatpickr/dist/flatpickr.css'
import {ImageInputComponent} from '../_metronic/assets/ts/components'
import {QUERIES, toAbsoluteUrl} from '../_metronic/helpers'
import CreatableSelect from 'react-select/creatable'
import {useQuery} from 'react-query'
import {getMetada} from '../app/modules/apps/job/list/core/_requests'
type Props = {
  value: string | number | Date | readonly (string | number | Date)[] | undefined
  defaultValue: string | undefined
  inputRef: any
}
export const CustomInput: FC<Props> = ({value, defaultValue, inputRef, ...props}) => {
  return <input {...props} defaultValue={defaultValue} ref={inputRef} />
}

export const DatePicker: FC<any> = ({field, form, ...props}) => {
  return (
    <Flatpickr
      onChange={([date]) => form.setFieldValue(field.name, date)}
      value={field.value}
      {...props}
      render={({defaultValue, value, ...props}, ref) => {
        return <CustomInput defaultValue={defaultValue} inputRef={ref} value={value} {...props} />
      }}
    />
  )
}

export const ImageUpload: FC<any> = ({field, form, ...props}) => {
  const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')
  useEffect(() => {
    ImageInputComponent.createInsance('#kt_image_input')
  }, [])
  return (
    <div
      id='kt_image_input'
      className='image-input image-input-outline'
      data-kt-image-input='true'
      style={{backgroundImage: `url('${props.blankImg || blankImg}')`}}
    >
      {/* begin::Preview existing avatar */}
      <div
        className='image-input-wrapper w-125px h-125px'
        style={{backgroundImage: `url('${props.blankImg || blankImg}')`}}
      ></div>
      {/* end::Preview existing avatar */}

      {/* begin::Label */}
      <label
        className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
        data-kt-image-input-action='change'
        data-bs-toggle='tooltip'
        title='Change avatar'
      >
        <i className='bi bi-pencil-fill fs-7'></i>

        <input
          type='file'
          onChange={(e) => form.setFieldValue(field.name, e.target.files ? e.target.files[0] : '')}
          name={field.name}
          accept='.png, .jpg, .jpeg'
        />
        <input type='hidden' name='avatar_remove' />
      </label>
      {/* end::Label */}

      {/* begin::Cancel */}
      <span
        className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
        data-kt-image-input-action='cancel'
        data-bs-toggle='tooltip'
        title='Cancel avatar'
      >
        <i className='bi bi-x fs-2'></i>
      </span>
      {/* end::Cancel */}

      {/* begin::Remove */}
      <span
        className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
        data-kt-image-input-action='remove'
        data-bs-toggle='tooltip'
        title='Remove avatar'
      >
        <i className='bi bi-x fs-2'></i>
      </span>
      {/* end::Remove */}
    </div>
  )
}

export const Switch: FC<any> = ({field, form, ...props}) => {
  return (
    <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
      <input
        className='form-check-input'
        type='checkbox'
        defaultChecked={field.value}
        onChange={(e) => form.setFieldValue(field.name, e.target.checked)}
      />
    </div>
  )
}
type SelectVal = {id?: number; value: string; label: string}
export const CustomSelect: FC<any> = ({field, form, ...props}) => {
  const fieldname = field.name.replace('metadata[', '').replace(']', '')
  const {data: metadata} = useQuery(
    `${QUERIES.METADATA_LIST}-filter=${JSON.stringify({name: fieldname})}}`,
    () => {
      return getMetada(`filter=${JSON.stringify({name: fieldname})}`)
    },
    {
      cacheTime: Infinity,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: fieldname ? true : false,
    }
  )
  const options = useMemo(() => {
    const result: SelectVal[] = []
    metadata?.entities?.forEach((m) => {
      result.push({id: m.id as number, label: m.value, value: m.value})
    })
    return result
  }, [metadata])
  const defaultOptions = useMemo(() => {
    if (field.value) {
      const result: SelectVal = {
        label: field.value,
        value: field.value,
      }
      return result
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value])
  const handleChange = (newValue: any) => {
    // const handleChange = (newValue: OnChangeValue<SelectVal, true>) => {
    // })
    form.setFieldValue(field.name, newValue.value)
  }
  return (
    <CreatableSelect
      isMulti={props.isMulti}
      onChange={handleChange}
      options={options}
      defaultValue={defaultOptions}
    />
  )
}

import {Route, Routes, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {JobsListWrapper} from './list/List'

const jobsBreadcrumbs: Array<PageLink> = [
  {
    title: 'Job',
    path: '/job',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const JobsPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          index
          element={
            <>
              <PageTitle breadcrumbs={jobsBreadcrumbs}>Jobs list</PageTitle>
              <JobsListWrapper />
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export {JobsPage}

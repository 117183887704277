import {useMemo} from 'react'
import {useQuery} from 'react-query'
import {QUERIES} from '../../../../../../_metronic/helpers'
import {getFields} from '../../../field/list/core/_requests'
import {useQueryRequest} from '../core/QueryRequestProvider'
import {Metadata} from '../core/_models'
import {getMetada} from '../core/_requests'

const Sidebar = () => {
  const {updateState, state} = useQueryRequest()
  const {data: field} = useQuery(
    `${QUERIES.FIELDS_LIST}-filter={"status":true,"type":"short_text_with_filter"}&sort_field=id&sort_order=asc`,
    () => {
      return getFields(
        'filter={"status":true,"type":"short_text_with_filter"}&sort_field=id&sort_order=asc'
      )
    },
    {cacheTime: Infinity, keepPreviousData: true, refetchOnWindowFocus: false}
  )
  const fields = useMemo(() => field?.entities?.map((f) => `"${f.name}"`), [field?.entities])
  const {data: metadata} = useQuery(
    `${QUERIES.METADATA_LIST}-filter={"status":true,"name":[${fields}]}&sort_field=id&sort_order=asc`,
    () => {
      return getMetada(`filter={"status":true,"name":[${fields}]}&sort_field=id&sort_order=asc`)
    },
    {
      cacheTime: Infinity,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: fields?.length ? true : false,
    }
  )

  const filterData = (val: Metadata) => {
    const value: any = {}
    value[val?.name] = val.value
    updateState({
      ...state,
      filter: {...(state.filter as any), ...value},
    })
  }
  const filter: any = useMemo(() => state.filter, [state.filter])

  return (
    <div className='flex-column flex-lg-row-auto w-100 w-xl-300px mb-10'>
      {/*begin::Catigories*/}
      {field?.entities?.map((f) => (
        <div key={f.id} className='mb-16'>
          <h4 className='text-dark mb-7'>{f.name}</h4>
          {/*begin::Item*/}

          {metadata?.entities
            ?.filter((entity) => entity.name === f.name)
            .map(
              (e, i) =>
                i < 10 && (
                  <div key={e.id} className='d-flex flex-stack fw-semibold fs-5 mb-4'>
                    {/*begin::Text*/}
                    <span
                      onClick={() => filterData(e)}
                      className={`${
                        filter && filter[f.name] === e.value ? '' : 'text-muted'
                      } text-hover-primary pe-2 cursor-pointer`}
                    >
                      {e.value}
                    </span>
                    {/*end::Text*/}
                  </div>
                )
            )}
          {/*end::Item*/}
        </div>
      ))}
      {/*end::Catigories*/}
    </div>
  )
}

export {Sidebar}

import axios from 'axios'
import {ID} from '../../../../../../_metronic/helpers'
import {Job, JobsQueryResponse, MetaDataQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const JOB_URL = `${API_URL}/job`
const METADATA_URL = `${API_URL}/metadata`

const getJobs = (query: string): Promise<JobsQueryResponse> => {
  return axios.get(`${JOB_URL}?${query}`)
}

const getJobById = (id: ID): Promise<Job | undefined> => {
  return axios.get(`${JOB_URL}/${id}`)
}

const createJob = (job: Job): Promise<Job | undefined> => {
  return axios.post(JOB_URL, job)
}

const updateJob = (job: Job): Promise<Job | undefined> => {
  return axios.patch(`${JOB_URL}`, job)
}

const deleteJob = (jobId: ID): Promise<void> => {
  return axios.delete(`${JOB_URL}/${jobId}`)
}

const deleteSelectedJobs = async (jobIds: Array<ID>): Promise<void> => {
  const requests = jobIds.map((id) => axios.delete(`${JOB_URL}/${id}`))
  await axios.all(requests)
}

const getMetada = (query: string): Promise<MetaDataQueryResponse> => {
  return axios.get(`${METADATA_URL}?${query}`)
}

export {getJobs, deleteJob, deleteSelectedJobs, getJobById, createJob, updateJob, getMetada}

import axios from 'axios'
import {ID} from '../../../../_metronic/helpers'
import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/auth/login_with_username_and_password`
export const REFRESH_URL = `${API_URL}/auth/login_using_refresh_token`
export const SWITCH_BUSINESS = `${API_URL}/auth/login_to_business_with_token`
export const REGISTER_URL = `${API_URL}/auth/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

// Server should return AuthModel
export async function login(
  username: string,
  password: string
): Promise<{token: string; business_list: {id: ID; name: string}[]}> {
  return await axios.post(LOGIN_URL, {
    username,
    password,
  })
}
// Server should return AuthModel
export async function switchBusiness(token: string, business_id: ID): Promise<AuthModel> {
  return await axios.post(SWITCH_BUSINESS, {
    token,
    business_id,
  })
}
export function refreshToken(refresh_token: string) {
  return axios.post<AuthModel>(REFRESH_URL, {
    refresh_token,
  })
}

// Server should return AuthModel
export async function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  const res = (await axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })) as AuthModel
  return res
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export async function getUserByToken() {
  const res = (await axios.get(GET_USER_BY_ACCESSTOKEN_URL)) as UserModel
  return res
}

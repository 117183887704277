import {useQuery} from 'react-query'
import {JobFetchModalPage} from './FetchModalPage'
import {isNotEmpty, QUERIES} from '../../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import {getJobById} from '../core/_requests'
import {useQueryResponseData} from '../core/QueryResponseProvider'
import {useMemo} from 'react'
import {initialJob} from '../core/_models'

const JobFetchModalPageWrapper = () => {
  const {itemIdToView, setItemIdToView} = useListView()
  const jobs = useQueryResponseData()
  const data = useMemo(() => jobs.find((u) => u.id === itemIdToView), [itemIdToView, jobs])
  const enabledQuery: boolean = isNotEmpty(itemIdToView)
  const {
    isLoading,
    data: job,
    error,
  } = useQuery(
    `${QUERIES.JOBS_LIST}-job-${itemIdToView}`,
    () => {
      return getJobById(itemIdToView)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdToView && setItemIdToView(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdToView) {
    return <JobFetchModalPage isJobLoading={isLoading} job={initialJob} />
  }

  if (!isLoading && !error && job) {
    return <JobFetchModalPage isJobLoading={isLoading} job={job} />
  } else if (data) return <JobFetchModalPage isJobLoading={isLoading} job={data} />

  return null
}

export {JobFetchModalPageWrapper}

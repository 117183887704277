/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useParams} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken, login, switchBusiness} from '../core/_requests'
import {ID} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'

const loginSchema = Yup.object().shape({
  username: Yup.string().required('Username is required'),
  password: Yup.string().required('Password is required'),
})

const initialValues = {
  username: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const [businesses, setBusinesses] = useState<{id: ID; name: string}[]>()
  const [selected, setSelected] = useState<ID>()
  const [token, setToken] = useState<string>()
  let {status, auth_token, refresh_token} = useParams()

  useEffect(() => {
    if (status === '1' && auth_token && refresh_token) {
      saveAuth({
        auth_token: decodeURIComponent(auth_token),
        refresh_token: decodeURIComponent(refresh_token),
      })
      getUserByToken().then((user) => setCurrentUser(user))
    }
    // eslint-disable-next-line
  }, [status, auth_token, refresh_token])

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const data = await login(values.username, values.password)
        setBusinesses(data.business_list)
        setToken(data.token)
      } catch (error: any) {
        saveAuth(undefined)
        setStatus(error.response.data.message || 'The login detail is incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  const businessSelected = async (business_id: ID) => {
    try {
      setLoading(true)
      setSelected(business_id)
      const data = await switchBusiness(token!, business_id)
      saveAuth(data)
      const user = await getUserByToken()
      setCurrentUser(user)
    } catch (error) {
      setSelected(undefined)
      saveAuth(undefined)
      setLoading(false)
    }
  }
  return businesses ? (
    <>
      {/*begin::Header*/}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label font-weight-bolder text-dark'>Please Select a Business</span>
        </h3>
      </div>
      {/*end::Header*/}
      {/*begin::Body*/}
      <div className='card-body pt-1'>
        {businesses.map((business, key) => (
          <div
            onClick={() => !selected && businessSelected(business.id)}
            key={key}
            className='d-flex align-items-center mb-3 bg-light-primary bg-hover-primary rounded-sm p-3 text-dark text-hover-light'
          >
            {/*begin::Symbol*/}
            <div className='symbol symbol-40 me-5'>
              <span className='symbol-label'>
                {selected === business.id && loading ? (
                  <span className='spinner-border spinner-border-sm align-middle'></span>
                ) : (
                  <i className='bi bi-building fs-2x text-primary'></i>
                )}
              </span>
            </div>
            {/*end::Symbol*/}
            {/*begin::Text*/}
            <div className='d-flex flex-column font-weight-bold'>
              <span
                // href='#'
                className='fw-bold text-capitalize'
              >
                {business.name}
              </span>
              {/* <span className='text-muted'>Project Manager</span> */}
            </div>
            {/*end::Text*/}
          </div>
        ))}
      </div>
      {/*end::Body*/}
    </>
  ) : (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>
          Sign with <a href='https://singlelogin.io'>Singlelogin.io</a> Credentials
        </h1>
      </div>
      {/* begin::Heading */}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Username</label>
        <input
          placeholder='Username'
          {...formik.getFieldProps('username')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.username && formik.errors.username},
            {
              'is-valid': formik.touched.username && !formik.errors.username,
            }
          )}
          type='username'
          name='username'
          autoComplete='off'
        />
        {formik.touched.username && formik.errors.username && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.username}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
            {/* end::Label */}
          </div>
        </div>
        <input
          type='password'
          placeholder='Password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  )
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {useQueryResponseLoading, useQueryResponsePagination} from '../../core/QueryResponseProvider'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {sizePerPageList} from '../../../../../../../_component/SizePerPageList'
import {PaginationState} from '../../../../../../../_metronic/helpers'

const JobsListPagination = () => {
  const pagination = useQueryResponsePagination()
  const isLoading = useQueryResponseLoading()
  const {updateState} = useQueryRequest()
  const updatePage = (page_number: number | null) => {
    if (!page_number || isLoading || pagination.page_number === page_number) {
      return
    }

    updateState({page_number, page_size: pagination.page_size || 10})
  }
  const updatePageSize = (event: any) => {
    const page_size = event.target.value as PaginationState['page_size']

    if (!page_size || isLoading || pagination.page_size === page_size) {
      return
    }

    updateState({page_size, page_number: 1})
  }
  const paginationList = pagination.total ? Math.ceil(pagination.total / pagination.page_size) : 1
  let availablePaginationList = [...Array(paginationList)].map((item, i) => i)
  const ls = availablePaginationList.filter((a) => a > pagination.page_number - 4)
  const ls2 = ls.filter((a) => a < pagination.page_number + 2)
  return (
    <div className='row'>
      <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
        <select
          disabled={pagination.total === 0}
          className={`form-control form-control-sm form-select me-4 border-0 bg-light ${
            pagination.total === 0 && 'disabled'
          }`}
          onChange={(e) => updatePageSize(e)}
          value={pagination.page_size}
          style={{width: '75px'}}
        >
          {sizePerPageList.map((option) => {
            const isSelect = pagination.page_size === option
            return (
              <option key={option} value={option} className={`btn ${isSelect ? 'active' : ''}`}>
                {option}
              </option>
            )
          })}
        </select>
        <div className='fs-6 fw-bold text-gray-700'>
          Showing {pagination.page_number * pagination.page_size - pagination.page_size + 1} to{' '}
          {(pagination.page_number - 1) * pagination.page_size + (pagination.count || 1)} of{' '}
          {pagination.total} entries
        </div>
      </div>
      <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
        <div id='kt_table_jobs_paginate'>
          <ul className='pagination'>
            <li
              className={clsx('page-item', {
                disabled: isLoading,
              })}
            >
              <a
                className={'page-link'}
                onClick={() => {
                  updatePage(1)
                }}
                style={{cursor: 'pointer'}}
              >
                <i className='fa-solid fa-angles-left text-dark'></i>
              </a>
            </li>
            <li
              className={clsx('page-item', {
                disabled: isLoading,
              })}
            >
              <a
                className={'page-link'}
                onClick={() => {
                  updatePage(
                    pagination.page_number > 1 ? pagination.page_number - 1 : pagination.page_number
                  )
                }}
                style={{cursor: 'pointer'}}
              >
                <i className='fa-solid fa-angle-left text-dark'></i>
              </a>
            </li>
            {pagination.page_number > 1 && (
              <li
                className={clsx('page-item', {
                  disabled: isLoading,
                })}
              >
                <a className='page-link' style={{cursor: 'pointer'}}>
                  ...
                </a>
              </li>
            )}
            {ls2.map((link, i) => (
              <li
                key={i}
                className={clsx('page-item', {
                  active: pagination.page_number === link + 1,
                  disabled: isLoading,
                })}
              >
                <a
                  className={'page-link'}
                  onClick={() => {
                    updatePage(link + 1)
                  }}
                  style={{cursor: 'pointer'}}
                >
                  {link + 1}
                </a>
              </li>
            ))}
            {pagination.page_number < paginationList && (
              <li
                className={clsx('page-item', {
                  disabled: isLoading,
                })}
              >
                <a className='page-link' style={{cursor: 'pointer'}}>
                  ...
                </a>
              </li>
            )}
            <li
              className={clsx('page-item', {
                disabled: isLoading,
              })}
            >
              <a
                className={'page-link'}
                onClick={() => {
                  updatePage(
                    pagination.page_number < paginationList
                      ? pagination.page_number + 1
                      : pagination.page_number
                  )
                }}
                style={{cursor: 'pointer'}}
              >
                <i className='fa-solid fa-angle-right text-dark'></i>
              </a>
            </li>
            <li
              className={clsx('page-item', {
                disabled: isLoading,
              })}
            >
              <a
                className={'page-link'}
                onClick={() => {
                  updatePage(paginationList)
                }}
                style={{cursor: 'pointer'}}
              >
                <i className='fa-solid fa-angles-right text-dark'></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export {JobsListPagination}

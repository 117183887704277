import {useQuery} from 'react-query'
import {JobEditModalForm} from './EditModalForm'
import {isNotEmpty, QUERIES} from '../../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import {getJobById} from '../core/_requests'
import {useQueryResponseData} from '../core/QueryResponseProvider'
import {useMemo} from 'react'
import {initialJob} from '../core/_models'

const JobEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const jobs = useQueryResponseData()
  const data = useMemo(() => jobs.find((u) => u.id === itemIdForUpdate), [itemIdForUpdate, jobs])
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: job,
    error,
  } = useQuery(
    QUERIES.FIELDS_LIST + '-job-' + itemIdForUpdate,
    () => {
      return getJobById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <JobEditModalForm isJobLoading={isLoading} job={initialJob} />
  }

  if (data || (!isLoading && !error && job)) {
    return <JobEditModalForm isJobLoading={isLoading} job={job || data || initialJob} />
  }

  return null
}

export {JobEditModalFormWrapper}

import {useAuth} from '../../../../app/modules/auth'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  const {currentUser} = useAuth()
  return (
    <>
      <AsideMenuItem
        to='/job'
        icon='/media/icons/duotune/text/txt009.svg'
        title='Job'
        fontIcon='bi-layers'
      />
      {currentUser && currentUser.role_level <= 4 && (
        <AsideMenuItem
          to='/field'
          icon='/media/icons/duotune/layouts/lay002.svg'
          title='Field'
          fontIcon='bi-app-indicator'
        />
      )}
    </>
  )
}

/* eslint-disable react-hooks/exhaustive-deps */
import {useState, useEffect} from 'react'
import {useQuery} from 'react-query'
import {
  initialQueryState,
  KTSVG,
  QUERIES,
  useDebounce,
} from '../../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {getMetada} from '../../core/_requests'

type Props = {
  Category?: string
  Location?: string
}
const JobsListSearchComponent = () => {
  const {updateState, state} = useQueryRequest()
  const [searchTerm, setSearchTerm] = useState<string>('')
  // Debounce search term so that it only gives us latest value ...
  // ... if searchTerm has not been updated within last 500ms.
  // The goal is to only have the API call fire when job stops typing ...
  // ... so that we aren't hitting our API rapidly.
  const debouncedSearchTerm = useDebounce(searchTerm, 150)
  // Effect for API call
  useEffect(
    () => {
      if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
        updateState({search: debouncedSearchTerm, ...initialQueryState})
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
    // More details about useDebounce: https://usehooks.com/useDebounce/
  )

  const filterData = (val: Props) => {
    updateState({
      ...state,
      filter: {...(state.filter as any), ...val},
    })
  }
  const {data: category} = useQuery(
    `${QUERIES.METADATA_LIST}-filter=${JSON.stringify({name: 'Category'})}}`,
    () => {
      return getMetada(`filter=${JSON.stringify({name: 'Category'})}`)
    },
    {
      cacheTime: Infinity,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  )
  const {data: location} = useQuery(
    `${QUERIES.METADATA_LIST}-filter=${JSON.stringify({name: 'Location'})}}`,
    () => {
      return getMetada(`filter=${JSON.stringify({name: 'Location'})}`)
    },
    {
      cacheTime: Infinity,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  )
  const {data: job_type} = useQuery(
    `${QUERIES.METADATA_LIST}-filter=${JSON.stringify({name: 'Job Type'})}}`,
    () => {
      return getMetada(`filter=${JSON.stringify({name: 'Job Type'})}`)
    },
    {
      cacheTime: Infinity,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  )
  return (
    <div className='card-title'>
      {/* begin::Search */}
      <div className='d-flex align-items-center position-relative my-1 me-2'>
        <KTSVG
          path='/media/icons/duotune/general/gen021.svg'
          className='svg-icon-1 position-absolute ms-6'
        />
        <input
          type='text'
          data-kt-job-table-filter='search'
          className='form-control form-control-solid w-250px ps-14'
          placeholder='Search jobs'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      {/* end::Search */}
      {/* begin::Category */}
      <div className='d-flex align-items-center position-relative my-1 me-2'>
        <select
          className='form-select form-select-solid w-250px ps-5'
          data-kt-select2='true'
          data-placeholder='Select option'
          data-allow-clear='true'
          data-kt-rating-table-filter='category'
          data-hide-search='true'
          onChange={(e) => filterData({Category: e.target.value})}
        >
          <option value=''>Category</option>
          {category?.entities?.map((e) => (
            <option key={e.id} value={e.value}>
              {e.value}
            </option>
          ))}
        </select>
      </div>
      {/* end::Category */}
      {/* begin::Location */}
      <div className='d-flex align-items-center position-relative my-1 me-2'>
        <select
          className='form-select form-select-solid w-250px ps-5'
          data-kt-select2='true'
          data-placeholder='Select option'
          data-allow-clear='true'
          data-kt-rating-table-filter='category'
          data-hide-search='true'
          onChange={(e) => filterData({Location: e.target.value})}
        >
          <option value=''>Location</option>
          {location?.entities?.map((e) => (
            <option key={e.id} value={e.value}>
              {e.value}
            </option>
          ))}
        </select>
      </div>
      {/* end::Location */}
      {/* begin::Location */}
      <div className='d-flex align-items-center position-relative my-1 me-2'>
        <select
          className='form-select form-select-solid w-250px ps-5'
          data-kt-select2='true'
          data-placeholder='Select option'
          data-allow-clear='true'
          data-kt-rating-table-filter='job_type'
          data-hide-search='true'
          onChange={(e) => {
            const jobtype: any = {}
            jobtype['Job Type'] = e.target.value
            filterData(jobtype)
          }}
        >
          <option value=''>Job Type</option>
          {job_type?.entities?.map((e) => (
            <option key={e.id} value={e.value}>
              {e.value}
            </option>
          ))}
        </select>
      </div>
      {/* end::Location */}
    </div>
  )
}

export {JobsListSearchComponent}

import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {JobsListHeader} from './components/header/ListHeader'
import {JobsTable} from './table/Table'
import {JobEditModal} from './edit-modal/EditModal'
import {KTCard} from '../../../../../_metronic/helpers'
import {JobFetchModal} from './fetch-modal/FetchModal'

const JobsList = () => {
  const {itemIdForUpdate, itemIdToView} = useListView()
  return (
    <>
      <KTCard>
        <JobsListHeader />
        <JobsTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <JobEditModal />}
      {itemIdToView !== undefined && <JobFetchModal />}
    </>
  )
}

const JobsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <JobsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {JobsListWrapper}

import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {JobsPage} from '../modules/apps/job/Page'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import {useAuth} from '../modules/auth'

const PrivateRoutes = () => {
  const FieldPage = lazy(() => import('../modules/apps/field/Page'))
  const MetaDataPage = lazy(() => import('../modules/apps/metadata/Page'))
  const {currentUser} = useAuth()

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/job' />} />
        {/* Pages */}
        <Route path='job/*' element={<JobsPage />} />
        {/* Lazy Modules */}
        {currentUser && currentUser?.role_level <= 4 ? (
          <>
            <Route
              path='field/*'
              element={
                <SuspensedView>
                  <FieldPage />
                </SuspensedView>
              }
            />
            <Route
              path='metadata/*'
              element={
                <SuspensedView>
                  <MetaDataPage />
                </SuspensedView>
              }
            />
          </>
        ) : (
          <Route path='*' element={<Navigate to='/job' />} />
        )}
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}

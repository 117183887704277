import {FC, useMemo} from 'react'
import * as Yup from 'yup'
import {Field, ErrorMessage, FormikValues, Formik, Form} from 'formik'
import {CustomSelect, Switch} from '../../../../../../_component/CustomInput'
import {isNotEmpty, QUERIES} from '../../../../../../_metronic/helpers'
import {Job} from '../core/_models'
import {useListView} from '../core/ListViewProvider'
import {ListLoading} from '../../../../../../_component/ListLoading'
import {createJob, updateJob} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {Editor} from '../../../../../../_component/CustomInputJs'
import {useQuery} from 'react-query'
import {getFields} from '../../../field/list/core/_requests'

type Props = {
  isJobLoading: boolean
  job: Job
}

const JobEditModalForm: FC<Props> = ({job, isJobLoading}) => {
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }
  const submit = async (values: Job, {setSubmitting}: Partial<FormikValues>) => {
    setSubmitting(true)
    try {
      if (isNotEmpty(values.id)) {
        await updateJob(values)
      } else {
        await createJob(values)
      }
      cancel(true)
    } catch (ex) {
      console.error(ex)
    } finally {
      setSubmitting(false)
    }
  }
  const {data: field} = useQuery(
    `${QUERIES.FIELDS_LIST}-filter={"status":true}&sort_field=id&sort_order=asc`,
    () => {
      return getFields('filter={"status":true}&sort_field=id&sort_order=asc')
    },
    {cacheTime: Infinity, keepPreviousData: true, refetchOnWindowFocus: false}
  )
  const editJobSchema = useMemo(() => {
    const validationObject1: {[key: string]: Yup.StringSchema} = {}

    // validation schema keys based on the name prop of the radio buttons in the form
    field?.entities?.forEach((entity) => {
      if (entity.required)
        validationObject1[`${entity.name}`] = Yup.string().required().label(entity.name)
      else validationObject1[`${entity.name}`] = Yup.string().label(entity.name)
    })
    const validationObject: {[key: string]: Yup.AnySchema} = {
      title: Yup.string().required().label('Title'),
      link: Yup.string().required().label('Link'),
      summary: Yup.string().required().label('Summary'),
      description: Yup.string().required().label('Job Description'),
      metadata: Yup.object(validationObject1),
    }
    return Yup.object(validationObject)
  }, [field])
  return (
    <Formik
      enableReinitialize={true}
      validationSchema={editJobSchema}
      initialValues={job}
      onSubmit={submit}
    >
      {(formik) => (
        <>
          <Form className='form' id='kt_modal_add_job_form'>
            <div
              className='d-flex flex-column scroll-y me-n7 pe-7'
              id='kt_modal_add_job_scroll'
              data-kt-scroll='true'
              data-kt-scroll-activate='{default: false, lg: true}'
              data-kt-scroll-max-height='auto'
              data-kt-scroll-dependencies='#kt_modal_add_job_header'
              data-kt-scroll-wrappers='#kt_modal_add_job_scroll'
              data-kt-scroll-offset='300px'
            ></div>
            <div className='row'>
              <div className='mb-5 fv-row col-lg-6'>
                <label className='form-label mb-3 required'>Job Title</label>

                <Field
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  name='title'
                />
                <div className='text-danger mt-2'>
                  <ErrorMessage name='title' />
                </div>
              </div>
              <div className='mb-5 fv-row col-lg-6'>
                <label className='form-label mb-3 required'>Job Link</label>

                <Field
                  type='url'
                  className='form-control form-control-lg form-control-solid'
                  name='link'
                />
                <div className='text-danger mt-2'>
                  <ErrorMessage name='link' />
                </div>
              </div>
              <div className='mb-5 fv-row col-lg-12'>
                <label className='form-label mb-3 required'>Summary</label>

                <Field
                  type='text'
                  as='textarea'
                  className='form-control form-control-lg form-control-solid'
                  name='summary'
                />
                <div className='text-danger mt-2'>
                  <ErrorMessage name='summary' />
                </div>
              </div>
              {field?.entities?.map(
                (f) =>
                  f.type !== 'long_text' && (
                    <div className='mb-5 fv-row col-lg-6' key={f.id}>
                      <label className={`form-label mb-3 ${f.required ? 'required' : ''}`}>
                        {f.name}
                      </label>

                      {f.type === 'short_text_with_filter' ? (
                        <Field
                          component={CustomSelect}
                          name={`metadata[${f.name}]`}
                          className='form-select form-select-lg form-select-solid'
                          isMulti={false}
                        />
                      ) : f.type === 'number' ? (
                        <Field
                          type='number'
                          className='form-control form-control-lg form-control-solid'
                          name={`metadata[${f.name}]`}
                        />
                      ) : (
                        f.type === 'short_text' && (
                          <Field
                            type='text'
                            className='form-control form-control-lg form-control-solid'
                            name={`metadata[${f.name}]`}
                          />
                        )
                      )}
                      <div className='text-danger mt-2'>
                        <ErrorMessage name={`metadata[${f.name}]`} />
                      </div>
                    </div>
                  )
              )}
              <div className='mb-5 fv-row col-lg-12'>
                <label className='form-label mb-3 required'>Job Description</label>

                <Field
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  name='description'
                  component={Editor}
                />
                <div className='text-danger mt-2'>
                  <ErrorMessage name='description' />
                </div>
              </div>
              {field?.entities?.map(
                (f) =>
                  f.type === 'long_text' && (
                    <div className='mb-5 fv-row col-lg-12' key={f.id}>
                      <label className={`form-label mb-3 ${f.required ? 'required' : ''}`}>
                        {f.name}
                      </label>
                      <Field
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        name={`metadata[${f.name}]`}
                        component={Editor}
                      />
                      <div className='text-danger mt-2'>
                        <ErrorMessage name={`metadata[${f.name}]`} />
                      </div>
                    </div>
                  )
              )}
              {isNotEmpty(formik.values.id) && formik.values.hasOwnProperty('status') && (
                <div className='mb-5 fv-row col-lg-6'>
                  <label className='form-label mb-3 required'>Status</label>

                  <Field
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='status'
                    component={Switch}
                  />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='status' />
                  </div>
                </div>
              )}
            </div>
            <div className='text-center pt-15'>
              <button
                type='reset'
                onClick={() => cancel()}
                className='btn btn-light me-3'
                data-kt-jobs-modal-action='cancel'
                disabled={formik.isSubmitting || isJobLoading}
              >
                Discard
              </button>

              <button
                type='submit'
                className='btn btn-primary'
                data-kt-jobs-modal-action='submit'
                disabled={isJobLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
              >
                <span className='indicator-label'>Submit</span>
                {(formik.isSubmitting || isJobLoading) && (
                  <span className='indicator-progress'>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </Form>
          {(formik.isSubmitting || isJobLoading) && <ListLoading />}
        </>
      )}
    </Formik>
  )
}

export {JobEditModalForm}

import axios from 'axios'
import {ID} from '../../../../../../_metronic/helpers'
import {Field, FieldsQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const CATEGORY_URL = `${API_URL}/field`

const getFields = (query: string): Promise<FieldsQueryResponse> => {
  return axios.get(`${CATEGORY_URL}?${query}`)
}

const getFieldById = (id: ID): Promise<Field | undefined> => {
  return axios.get(`${CATEGORY_URL}/${id}`)
}

const createField = (field: Field): Promise<Field | undefined> => {
  return axios.post(CATEGORY_URL, field)
}

const updateField = (field: Field): Promise<Field | undefined> => {
  return axios.patch(`${CATEGORY_URL}`, field)
}

const deleteField = (fieldId: ID): Promise<void> => {
  return axios.delete(`${CATEGORY_URL}/${fieldId}`)
}

const deleteSelectedFields = async (fieldIds: Array<ID>): Promise<void> => {
  const requests = fieldIds.map((id) => axios.delete(`${CATEGORY_URL}/${id}`))
  await axios.all(requests)
}

export {getFields, deleteField, deleteSelectedFields, getFieldById, createField, updateField}

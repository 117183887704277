import {ID, Response} from '../../../../../../_metronic/helpers'
export type Job = {
  id?: ID
  title: string
  link: string
  description: string
  summary: string
  status?: boolean
  created_at?: Date
  updated_at?: Date
  deleted_at?: Date
  created_by?: string
  updated_by?: string
  metadata: any
}
export type Metadata = {
  id?: ID
  name: string
  value: string
}

export type JobsQueryResponse = Response<Array<Job>>
export type MetaDataQueryResponse = Response<Array<Metadata>>

export const initialJob: Job = {
  title: '',
  link: '',
  description: '',
  summary: '',
  metadata: {},
}

import {KTSVG, QUERIES} from '../../../../../../_metronic/helpers'
import {useAuth} from '../../../../auth'
import {useListView} from '../core/ListViewProvider'
import {useMutation, useQueryClient} from 'react-query'
import {deleteJob} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'

const JobFetchModalHeader = () => {
  const {setItemIdForUpdate, setItemIdToView, itemIdToView} = useListView()
  const {currentUser} = useAuth()
  const openEditModal = () => {
    setItemIdForUpdate(itemIdToView)
    setItemIdToView && setItemIdToView(undefined)
  }
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()
  const deleteItem = useMutation(() => deleteJob(itemIdToView), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.JOBS_LIST}-${query}`])
      setItemIdToView && setItemIdToView(undefined)
    },
  })
  return (
    <div className='modal-header'>
      {/* begin::Modal title */}
      <h2 className='fw-bolder'>View Job</h2>
      {/* end::Modal title */}

      {/* begin::Edit */}
      <div>
        {currentUser && currentUser.role_level <= 4 && (
          <div
            className='btn btn-icon btn-sm btn-active-icon-primary'
            data-kt-jobs-modal-action='edit'
            onClick={openEditModal}
            style={{cursor: 'pointer'}}
          >
            <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-1' />
          </div>
        )}
        {/* end::Edit */}
        {/* begin::Delete */}
        {currentUser && currentUser.role_level <= 4 && (
          <div
            className='btn btn-icon btn-sm btn-active-icon-danger'
            data-kt-jobs-modal-action='delete'
            onClick={async () => await deleteItem.mutateAsync()}
            style={{cursor: 'pointer'}}
          >
            <KTSVG path='/media/icons/duotune/art/art004.svg' className='svg-icon-1' />
          </div>
        )}
        {/* end::Delete */}
        {/* begin::Close */}
        <div
          className='btn btn-icon btn-sm btn-active-icon-primary'
          data-kt-jobs-modal-action='close'
          onClick={() => setItemIdToView && setItemIdToView(undefined)}
          style={{cursor: 'pointer'}}
        >
          <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
        </div>
      </div>
      {/* end::Close */}
    </div>
  )
}

export {JobFetchModalHeader}

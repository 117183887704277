import React from 'react'
import {useAuth} from '../../../../app/modules/auth'
import {MenuItem} from './MenuItem'

export function MenuInner() {
  const {currentUser} = useAuth()
  return (
    <>
      <MenuItem title='Job' to='/job' />
      {currentUser && currentUser.role_level <= 4 && <MenuItem title='Field' to='/field' />}
    </>
  )
}

import {useMemo} from 'react'
import {useQueryResponseData, useQueryResponseLoading} from '../core/QueryResponseProvider'
import {ListLoading} from '../../../../../../_component/ListLoading'
import {JobsListPagination} from '../components/pagination/ListPagination'
import {ID} from '../../../../../../_metronic/helpers'
import dayjs from 'dayjs'
import {useListView} from '../core/ListViewProvider'
import {Sidebar} from './SideBar'

const JobsTable = () => {
  const jobs = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => jobs, [jobs])
  const {setItemIdToView} = useListView()

  const openFetchModal = (id: ID) => {
    setItemIdToView && setItemIdToView(id)
  }
  return (
    <div className='card'>
      {/*begin::Body*/}
      <div className='card-body p-lg-20 py-lg-5'>
        {/*begin::Layout*/}
        <div className='d-flex flex-column flex-xl-row'>
          {/*begin::Content*/}
          <div className='flex-lg-row-fluid me-xl-15'>
            {/*begin::Post content*/}
            {data.length > 0 ? (
              <>
                {data.map((d) => (
                  <div key={d.id} className='mb-17'>
                    {/*begin::Wrapper*/}
                    <div className='mb-4'>
                      <div className='d-flex flex-wrap mb-2'>
                        {/*begin::Item*/}
                        <div className='me-9 my-1'>
                          {/*begin::Icon*/}
                          {/*begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-09-15-053640/core/html/src/media/icons/duotune/maps/map008.svg*/}
                          <span className='svg-icon svg-icon-primary svg-icon-2 me-1'>
                            <svg
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M3 2H10C10.6 2 11 2.4 11 3V10C11 10.6 10.6 11 10 11H3C2.4 11 2 10.6 2 10V3C2 2.4 2.4 2 3 2Z'
                                fill='currentColor'
                              />
                              <path
                                opacity='0.3'
                                d='M14 2H21C21.6 2 22 2.4 22 3V10C22 10.6 21.6 11 21 11H14C13.4 11 13 10.6 13 10V3C13 2.4 13.4 2 14 2Z'
                                fill='currentColor'
                              />
                              <path
                                opacity='0.3'
                                d='M3 13H10C10.6 13 11 13.4 11 14V21C11 21.6 10.6 22 10 22H3C2.4 22 2 21.6 2 21V14C2 13.4 2.4 13 3 13Z'
                                fill='currentColor'
                              />
                              <path
                                opacity='0.3'
                                d='M14 13H21C21.6 13 22 13.4 22 14V21C22 21.6 21.6 22 21 22H14C13.4 22 13 21.6 13 21V14C13 13.4 13.4 13 14 13Z'
                                fill='currentColor'
                              />
                            </svg>
                          </span>
                          {/*end::Svg Icon*/}
                          {/*end::Icon*/}
                          {/*begin::Label*/}
                          <span className='fw-bold text-gray-400'>{d.metadata?.Category}</span>
                          {/*end::Label*/}
                        </div>
                        {/*end::Item*/}
                        {/*begin::Item*/}
                        <div className='me-9 my-1'>
                          {/*begin::Icon*/}
                          {/*SVG file not found: icons/duotune/finance/fin006.svgFolder.svg*/}
                          {/*end::Icon*/}
                          {/*begin::Label*/}
                          <span className='fw-bold text-gray-400 text-capitalize'>
                            {dayjs(d.created_at).format('DD MMM YYYY')}
                          </span>
                          {/*begin::Label*/}
                        </div>
                        {/*end::Item*/}
                        {/*begin::Item*/}
                        <div className='my-1'>
                          {/*begin::Icon*/}
                          {/*begin::Svg Icon | path: icons/duotune/communication/com003.svg*/}
                          <span className='svg-icon svg-icon-primary svg-icon-2 me-1'>
                            <svg
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                opacity='0.3'
                                d='M18.0624 15.3454L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3454C4.56242 13.6454 3.76242 11.4452 4.06242 8.94525C4.56242 5.34525 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24525 19.9624 9.94525C20.0624 12.0452 19.2624 13.9454 18.0624 15.3454ZM13.0624 10.0453C13.0624 9.44534 12.6624 9.04534 12.0624 9.04534C11.4624 9.04534 11.0624 9.44534 11.0624 10.0453V13.0453H13.0624V10.0453Z'
                                fill='currentColor'
                              />
                              <path
                                d='M12.6624 5.54531C12.2624 5.24531 11.7624 5.24531 11.4624 5.54531L8.06241 8.04531V12.0453C8.06241 12.6453 8.46241 13.0453 9.06241 13.0453H11.0624V10.0453C11.0624 9.44531 11.4624 9.04531 12.0624 9.04531C12.6624 9.04531 13.0624 9.44531 13.0624 10.0453V13.0453H15.0624C15.6624 13.0453 16.0624 12.6453 16.0624 12.0453V8.04531L12.6624 5.54531Z'
                                fill='currentColor'
                              />
                            </svg>
                          </span>
                          {/*end::Svg Icon*/}
                          {/*end::Icon*/}
                          {/*begin::Label*/}
                          <span className='fw-bold text-gray-400'>{d.metadata['Location']}</span>
                          {/*end::Label*/}
                        </div>
                        {/*end::Item*/}
                      </div>
                      {/*begin::Title*/}
                      <span
                        onClick={() => openFetchModal(d.id)}
                        className='text-primary text-hover-dark fs-2 fw-bold cursor-pointer'
                      >
                        {d.title} |{' '}
                        <i className='fw-bold text-muted fs-5 ps-1'>{d.metadata['Company Name']}</i>
                      </span>
                      {/*end::Title*/}
                    </div>
                    {/*end::Wrapper*/}
                    {/*begin::Description*/}
                    <div className='fs-5 fw-semibold text-gray-600'>
                      {/*begin::Text*/}
                      <p className='mb-6'>
                        {d.summary?.substring(0, 300).concat(d.summary?.length > 300 ? ' ...' : '')}
                      </p>
                      {/*end::Text*/}
                    </div>
                    {/*end::Description*/}
                  </div>
                ))}
                <JobsListPagination />
                {!data.length && isLoading && <ListLoading />}
              </>
            ) : (
              <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                No records found
              </div>
            )}
            {/*end::Post content*/}
          </div>
          {/*end::Content*/}
          <Sidebar />
        </div>
        {/*end::Layout*/}
      </div>
      {/*end::Body*/}
    </div>
  )
}

export {JobsTable}

import {AuthModel} from './_models'
import {toast} from 'react-toastify'
import {AxiosError, AxiosResponse} from 'axios'
import {refreshToken} from './_requests'

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}
export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: {headers: {Authorization: string}}) => {
      const auth = getAuth()
      if (auth && auth.auth_token) {
        config.headers.Authorization = `Bearer ${auth.auth_token}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
  axios.interceptors.response.use(
    (response: AxiosResponse) => {
      response.data?.message && toast.success(response.data.message)
      return response.data.data
    },
    async (error: AxiosError) => {
      const auth = getAuth()
      const originalRequest: any = error.config
      if (error?.response?.status === 403 && auth?.refresh_token && !originalRequest._retry) {
        try {
          originalRequest._retry = true
          // const splashScreen = document.getElementById('splash-screen')
          // if (splashScreen) splashScreen.style.display = ''
          const response = await refreshToken(auth.refresh_token)
          // if (splashScreen) splashScreen.style.display = 'none'
          if (response.status === 200) {
            setAuth(response.data)
            return axios(originalRequest)
          } else {
            removeAuth()
            throw error
          }
        } catch (error) {
          removeAuth()
          throw error
        }
      } else if (error?.response?.status === 401) {
        removeAuth()
        throw error
      } else {
        const data: any = error?.response?.data
        if (data?.message) toast.error(data.message)
        throw error
      }
    }
  )
}

export {getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY}
